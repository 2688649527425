import { isEmpty } from 'lodash';
import { HttpService } from '~/helpers';
import { store } from '~/stores';
import { actions } from '~/stores/ducks';
import { Strings } from '~/helpers/general';
import { RememberService, CustomerService } from '~/services/general';

const endpoint = 'sistema/login';

const UserService = {
  login: ({ usuario, senha, lembrar, empresa }, onSuccess, onError) => {
    const remember = RememberService.getRemember();

    HttpService.call(
      endpoint,
      'valida_login',
      [usuario, senha, empresa],
      response => {
        const { retorno, dados_empresa: customer } = response;

        if (customer && customer.idt > 0) {
          CustomerService.setCustomer(customer);
        }

        RememberService.setRemember({
          ...remember,
          token: response.token,
        });

        if (retorno && retorno.length === 1) {
          UserService.setUser(retorno[0]);

          if (lembrar === 'S') {
            RememberService.setRemember({
              ...(usuario === remember.usuario && remember.customer === customer.nome && remember),
              usuario,
              senha,
              customer: customer.nome,
              logo_base64: customer.logo_base64,
              lembrar,
              token: response.token,
            });
          } else {
            RememberService.resetRemember();
          }

          if (onSuccess) {
            onSuccess(response);
          }
        } else if (onError) {
          onError(response);
        }
      },
      onError
    );
  },
  isLogado: () => !isEmpty(UserService.getUser()) && !isEmpty(CustomerService.getCustomer()),
  setUser: user => store.dispatch(actions.setUser(user)),
  getUser: () => store.getState().user,
  logout: onSuccess => {
    store.dispatch({ type: Strings.REDUX_RESET_KEY });

    if (onSuccess) {
      onSuccess();
    }
  },
  verifica_versao: onSuccess => {
    HttpService.call('sistema/verifica_versao', 'verificar', [document.URL], onSuccess);
  },
  verifica_horario: (codigo, onSuccess, onError) => {
    HttpService.call(endpoint, 'verifica_horario', [codigo], onSuccess, onError);
  },
  esqueciMinhaSenha: (email, onSuccess, onError) => {
    HttpService.call(endpoint, 'esqueciMinhaSenha', [email], onSuccess, onError);
  },
  alterarSenha: (values, onSuccess, onError) => {
    HttpService.call(
      endpoint,
      'alterar_senha',
      [values.nova_senha, UserService.getUser().codigo, UserService.getUser().login, values.senha_atual],
      onSuccess,
      onError
    );
  },
  atualizarCadastro: (values, onSuccess, onError) => {
    HttpService.call(
      endpoint,
      'atualizarCadastro',
      [{ ...values, codigo: UserService.getUser().codigo, usuario: UserService.getUser().login }],
      onSuccess,
      onError
    );
  },
  buscaParametros: (codigo, filial, dataPedido, contrato, chave, onSuccess, onError) => {
    HttpService.call(endpoint, 'busca_parametros', [codigo, filial, dataPedido, contrato, chave], onSuccess, onError);
  },
  getAllRecados: (cliente, filial, onSuccess, onError) => {
    HttpService.call(endpoint, 'getAllRecados', [cliente, filial], onSuccess, onError);
  },
  fetchEmpresas: (onSuccess, onError) => {
    HttpService.call(endpoint, 'combo_empresas', [], onSuccess, onError);
  },
  fetchContentores: (cliente, filial, onSuccess, onError) => {
    HttpService.call(endpoint, 'saldo_contentores', [cliente, filial], onSuccess, onError);
  },
  fetchGrupoProduto: (onSuccess, onError) => {
    HttpService.call(endpoint, 'combo_grupo_produto', [], onSuccess, onError);
  },
  fetchSubGrupoProduto: (onSuccess, onError) => {
    HttpService.call(endpoint, 'combo_subgrupo_produto', [], onSuccess, onError);
  },
  fetchGrupoSeparacao: (onSuccess, onError) => {
    HttpService.call(endpoint, 'combo_grupo_separacao', [], onSuccess, onError);
  },
  fetchFuncionario: (onSuccess, onError) => {
    HttpService.call(endpoint, 'combo_cad_funcionario', [], onSuccess, onError);
  },
  fetchFuncoinarioFuncao: (onSuccess, onError) => {
    HttpService.call(endpoint, 'combo_cad_funcionario_funcao', [], onSuccess, onError);
  },
  getLayoutTableReact: (tipo, onSuccess) => {
    HttpService.call(endpoint, 'layout_table_react', [tipo], onSuccess);
  },
  insertLayoutTableReact: (values, onSuccess, onError) => {
    HttpService.call(endpoint, 'insertLayoutTableReact', [{ ...values, usuario: UserService.getUser().login }], onSuccess, onError);
  },
  inserir_erro_sistema: (message, stack, tela, base64image, onSuccess) => {
    HttpService.call(endpoint, 'inserir_erro_sistema', [message, stack, tela, base64image], onSuccess);
  },
  inserir_erro_imagem: (message, base64image) => {
    HttpService.call(endpoint, 'inserir_erro_imagem', [message, base64image]);
  },
  inserirLogPage: values => {
    HttpService.call('sistema/help', 'inserirLogPage', [values, UserService.getUser().nome]);
  },
  inserir_log_usuario: values => {
    HttpService.call('sistema/help', 'inserir', [{ ...values, usuario: UserService.getUser().login }]);
  },
  fetchReleaseNote: (onSuccess, onError) => {
    HttpService.call('sistema/help', 'fetchReleaseNote', [process.env.NODE_ENV === 'production' ? process.env.REACT_APP_VERSION : ''], onSuccess, onError);
  },
  fetchReleaseNoteItens: (versao, onSuccess, onError) => {
    HttpService.call('sistema/help', 'fetchReleaseNoteItens', [versao], onSuccess, onError);
  },
};

export default UserService;
